
import debounce from './global/debounce';


const selectors = {
	image : '[data-imageswap-image]',
	trigger : '[data-imageswap-trigger]',
}


function changeImage(newImage, allImages) {
	allImages.forEach(image => image.style.opacity = 0);
	newImage.style.opacity = 1;
}

const debouncedChangeImage = debounce(changeImage, 200);


function createMouseleaveHandler(firstImage, allImages, triggers) {
	return (event) => {
		if (event.target !== event.currentTarget) return false;
		event.stopPropagation();
		const newHoverIsTriggerElem = (triggers.indexOf(event.relatedTarget) >= 0);
		if (!newHoverIsTriggerElem) {
			debouncedChangeImage(firstImage, allImages);
		}
	}
}

function createMouseenterHandler(image, allImages) {
	return (event) => {
		if (event.target !== event.currentTarget) return false;
		event.stopPropagation();
		debouncedChangeImage(image, allImages);
	}
}


export const initInstance = function(el) {

	const firstImage = el.querySelector(selectors.image);
	const allImages = [...el.querySelectorAll(selectors.image)]
	const images = allImages.filter(image => image !== firstImage);
	const triggers = [...el.querySelectorAll(selectors.trigger)];
	
	// set initial styles
	images.forEach(image => {
		image.style.opacity = 0;
	});

	triggers.forEach(trigger => {
		const index = triggers.indexOf(trigger);
		const image = images[index] ? images[index] : null;
		const mouseenterHandler = createMouseenterHandler(image, allImages);
		const mouseleaveHandler = createMouseleaveHandler(firstImage, allImages, triggers);
		trigger.addEventListener('mouseenter', mouseenterHandler);
		trigger.addEventListener('mouseleave', mouseleaveHandler);
	});
}
